import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import NewsList from "./../../Components/NewsList";

const EMList = [
  'https://lh3.googleusercontent.com/pw/AP1GczOmNQi8BF8KvwEP4niiZMBMyywKDnuWNbivdON-8oT282aPFSndetpScSBIzUBcD1gb0OfBiCDGSIAOnwMiVY3ed4c2MwujcaqOI4iK0nmWvVBesbM=w1920-h1080',
  'https://lh3.googleusercontent.com/pw/AP1GczPaWA2xNcHTARmboIqkxvka_k07e39n8FIouqkHJ9CKR-4CoGpT64LG2lB7ZArUI1px4x-6oZ2W_2cewThX_qwLGO2EcRY7z77YssE7ZFFoeBGbvig=w1920-h1080',
  'https://lh3.googleusercontent.com/pw/AP1GczNfV_2aHgsj62286X5FFp5kl0kupxCSogthPrW3o7DdX9oURG-AtwUuN7y77-hI1Dh89ch0OcRy_DiwOAy_sYT0yjZEhwXYkLNmoL1PaFjUJfcrbKo=w1920-h1080',
  'https://lh3.googleusercontent.com/pw/AP1GczMLBwcrsk06RP4K0c4EzoMDjIT4TIRBJ2eQjY5_thABA4PWOvfSusHr62qGuhTtD3e02cvxxmMXmAej22ioZt-VvvGCDPCIgZZKRHnXVZQKPpurY_A=w1920-h1080',
  'https://lh3.googleusercontent.com/pw/AP1GczMWfFIzshmyNg-3JSEIOKBq7EQuNqu17QB7_nAtH-mK4uk_VkHKPUhuo5C0eQh1UOCuIVhglwkRBFAkT2p_SdDjV5rvYAQWP0Flk59mg1DTXgQp06A=w1920-h1080',
  'https://lh3.googleusercontent.com/pw/AP1GczO5eK54b4jGZmdnAUqvMIXyfwRDP6RJItsy6-f3Cd2zlXlp7Ih121OABgiwxGmEMFMLUdup7EDybOIR_5F3QCrjypy-7L222fLwGlyWpIFsTPNCzjg=w1920-h1080',
  'https://lh3.googleusercontent.com/pw/AP1GczPiXKjAsIFeu1MA7iFBD_6_zndPPPinuwgkpbuTa6OaVWBXvASMGvCTJkpaNe_t5xOqC-dAFZKKrrF-Z0iCBR4sbcOkyWguMvzJHpcHSV7yZlL3uU4=w1920-h1080',
  'https://lh3.googleusercontent.com/pw/AP1GczMs-Wqpxrt4chzc3olw2TucCoUhwUsKXXAVcAhgm-m5bpo2z29aj2CIYT2yPCinAiRSUJno_JR8XQ3Ow0Eypq3pfpSkhHEHgkUp-8KiJrAz1vZvlcg=w1920-h1080',
  'https://lh3.googleusercontent.com/pw/AP1GczN18Sk7Xw72vGXaLIGAhM7CDmc4aR3C5bgB2MjO7PzCDh2e-SiDMHGo_Mf-jsPuzDUydTLiBg3VeJMcl4Bd0IaBnMJ6fLOaCaTKG1TzKF1OjU9H1ho=w1920-h1080',
  'https://lh3.googleusercontent.com/pw/AP1GczOmKmk0W3Bm1IPxu2dWn2q7JzXIBOnf7jANt_3maC8oDGe0FxkdmRnYs7qMZ9xyyeOcJb2PoC619eV1t81tkn22HTuFqcifAUcgTq31gYimOYie1kE=w1920-h1080',
  'https://lh3.googleusercontent.com/pw/AP1GczNdVPqc4SzWdwkpVnpZ560XbcuUqXwWCqvrj64tAjw-YhLcUmwj74sWN9CSKFqZFnfxpELP_jhxvSVdTfgzf2mCVphjd1Ixdj8S5WX-UTvISdcH4bI=w1920-h1080',
  'https://lh3.googleusercontent.com/pw/AP1GczMQ7lZGOI_bFy2B0-M0sodo0GEv_IMaYQwj1dCHTqnFwsGFVcmHRJLdyz7m-_oeqols1r1YPctPerWD__KSS860zuU0er-9XaXtEVT18qnPwlqRtN0=w1920-h1080',
  'https://lh3.googleusercontent.com/pw/AP1GczOyhAvozcUo2qQ2jewk4v_k3RVlrdRnJ9okHFb6Ek_b5mjQfh6-mHhvH2TQoCklH_NzkZ9b-5WbSVzKnh_4FjM1sQuRXn1qGxI9lWZHkVjZWmCmDuY=w1920-h1080',
  'https://lh3.googleusercontent.com/pw/AP1GczNJuUImeLzFWRn87PJNcx415wjLiOKaetN3pFhh0rob6yfMm_idnT5llI6JEHDbGUmytQMU3_Pwd2GA4896HotiNsw0M-_RVux-0H7kLg6Tz4eAGv4=w1920-h1080',
  'https://lh3.googleusercontent.com/pw/AP1GczPbp9MyYyFfy66d4rgXdBqR9fTHRsOJGmbZBTq3SwPrRPysE35jdoctBqJ0dgX-jzIpgPnVDJWw_620CjkG1oQOKN4FzxtrwkhU3T3Ohxo1LlpgxRc=w1920-h1080',
  'https://lh3.googleusercontent.com/pw/AP1GczMHnk2Li47_emJTirjxK60cy-dEkACU_cLrU7sjp8HxF4rBhZE5QYRCSCo_fzaiuB6jWfGrzq5Tj2Sd8M13q8N1iPBt63TZq-IaWNrjKcepVsvPVFY=w1920-h1080',
  'https://lh3.googleusercontent.com/pw/AP1GczPPLA7fk8O8ALOxms1NbjBGUVBK1Odn23JSH28K7ojC6aTMcCELPJyLdusPe2cdP4Ba1qZFZpWAspvir2Uy7p3-rOdIGkGSHHO88XVYqShYJbLCQx8=w1920-h1080',
  'https://lh3.googleusercontent.com/pw/AP1GczOkNJiSQ4eeIO2SLnwbbBLv1FDysUaZydXgnkFRyeXtBpCkeA65qwmKAxXeBmdaaDl7BnqeDUPEB8zv0d8xYCMySErejBwzIH_pkvyWZrsMMmP3Lzc=w1920-h1080',
]

export default function LaunchingCreativeTechnologiesWithGenAIChennai2024() {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);
  return (
    <div className='content' style={{ paddingTop: '74px' }}>
      <div className='container-fluid sectin float-none'>
        <div className='news-lt'>
          <div className="pa-gallery-player-widget wdth-100p mb-3 hght-480" data-link="https://photos.app.goo.gl/7j3MLPMMY155jKSb9" data-title="Launching Creative Technologies With Gen AI Chennai - 2024" data-description="18 new items added to shared album">
            {EMList.map((iMg, index) => {
              return (
                <object key={index} data={iMg} aria-label="Launching Creative Technologies With Gen AI Chennai - 2024"></object>
              )
            })}
          </div>
          <h2 className="h4 mb-3">Launching Creative Technologies With Gen AI Chennai - 2024</h2>
          <div className='news-arrows'>
            <Link to='/12th-graduate-showcase-2023-hyderabad' className='prev'>New Post</Link>
            <Link to='/12th-graduate-showcase-2022-bangalore' className='next float-end'>Older Post</Link>
          </div>
        </div>
        <div className='news-rt'>
          <ul>
            <NewsList />
          </ul>
        </div>
      </div>
    </div>
  )
}