import React from 'react';
import { Link } from 'react-router-dom';

import { useLocation } from "react-router-dom";

const NewsLt = [
    {
        title: "Launching Creative Technologies With Gen AI Chennai - 2024",
        path: "/launching-creative-technologies-with-gen-ai-chennai-2024",
        category: "IMAGE",
        cClass: "news-category1"
    },
    {
        title: "ஓவியக் கலையை வளர்ப்பதற்கான முதல் முயற்சியாக நடத்தப்பட்ட 'இமேஜ் ஆர்ட் சேலஞ்ச் 2024'",
        path: "/iac-prize-distribution-ceremony-chennai-2024",
        category: "IMAGE Group",
        cClass: "news-category3",
        tClass: "noto-t",
    },
    {
        title: "My India My Pride - Photography Contest Winners and Runner prize distribution",
        path: "/photography-contest-winners-runner-prize-distribution",
        category: "IMAGE",
        cClass: "news-category1"
    },
    {
        title: "Comfusionism - Miraki 2023 - Inter Collegiate Ciltural Fest, Chennai",
        path: "/comfusionism-miraki-2023-chennai",
        category: "ICAT",
        cClass: "news-category2"
    },
    {
        title: "RAMPCULTURE - Image Creative Education fashion dept students participated in Avant Garde Fashion show 2023",
        path: "/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023",
        category: "IMAGE",
        cClass: "news-category1"
    },
    {
        title: "AVGC Stakeholder Consultation workshop on TN AVGC-XR Policy 2023",
        path: "/AVGC-stakeholder-consultation-workshop",
        category: "ICAT",
        cClass: "news-category2"
    },

    {
        title: "Inter-Collegiate Cultural Fest - Miraki 2023",
        path: "/inter-collegiate-cultural-fest-miraki-2023-chennai",
        category: "ICAT",
        cClass: "news-category2"
    },
    {
        title: "15th Graduation Showcase of ICAT Design & Media College, Chennai",
        path: "/15th-graduate-showcase-2023-chennai",
        category: "ICAT",
        cClass: "news-category2"
    },
    {
        title: "13th Graduation Showcase of ICAT Design & Media College, Bangalore",
        path: "/13th-graduate-showcase-2023-bangalore",
        category: "ICAT",
        cClass: "news-category2"
    },
    {
        title: "12th Graduation Showcase of ICAT Design & Media College, Hyderabad",
        path: "/12th-graduate-showcase-2023-hyderabad",
        category: "ICAT",
        cClass: "news-category2"
    },

    {
        title: "14th Graduation Showcase of ICAT Design & Media College, Chennai",
        path: "/14th-graduate-showcase-2022-chennai",
        category: "ICAT",
        cClass: "news-category2"
    },

    {
        title: "12th Graduation Showcase of ICAT Design & Media College, Bangalore",
        path: "/12th-graduate-showcase-2022-bangalore",
        category: "ICAT",
        cClass: "news-category2"
    },

    {
        title: "Student Showcase of ICAT Design & Media College, Chennai",
        path: "/student-showcase-2021-chennai",
        category: "ICAT",
        cClass: "news-category2"
    },

    {
        title: "11th BCU Convocation 2020",
        path: "/11th-bcu-convocation-ceremony-2020",
        category: "ICAT",
        cClass: "news-category2"
    },

    {
        title: "Inter-Collegiate Cultural Fest - Miraki 2020",
        path: "/inter-collegiate-cultural-fest-miraki-2020-bangalore",
        category: "ICAT",
        cClass: "news-category2"
    },

    {
        title: "Inter-Collegiate Cultural Fest - Miraki 2020",
        path: "/inter-collegiate-cultural-fest-miraki-2020-chennai",
        category: "ICAT",
        cClass: "news-category2"
    },

    {
        title: "9th Graduation Showcase of ICAT Design & Media College, Bangalore",
        path: "/9th-graduate-showcase-2019-bangalore",
        category: "ICAT",
        cClass: "news-category2"
    },

    {
        title: "9th Graduation Showcase of ICAT Design & Media College, Hyderabad",
        path: "/9th-graduate-showcase-2019-hyderabad",
        category: "ICAT",
        cClass: "news-category2"
    },

    {
        title: "11th graduation showcase of ICAT Design & Media College, Chennai",
        path: "/11th-graduate-showcase-2019-chennai",
        category: "ICAT",
        cClass: "news-category2"
    },



    {
        title: "IMAGEMINDS Won the 'Most Inclusive Educational Programme - Gold' Award",
        path: "/won-most-inclusive-educational-programme-gold-award",
        category: "Imageminds",
        cClass: "news-category3"
    },
    {
        title: "Raja Raja Chozhan - 2D Animation Short film released",
        path: "/raja-raja-chozhan-2D-animation-short-film-released-2019",
        category: "IMAGE",
        cClass: "news-category1"
    },
    {
        title: "Inter-Collegiate Cultural Fest - Miraki 2K19, Chennai",
        path: "/inter-collegiate-cultural-fest-miraki-2019-chennai",
        category: "ICAT",
        cClass: "news-category2"
    },
    {
        title: "Inter-Collegiate Cultural Fest - Miraki 2K19, Bangalore",
        path: "/inter-collegiate-cultural-fest-miraki-2019-bangalore",
        category: "ICAT",
        cClass: "news-category2"
    },
    {
        title: "10th Convocation Ceremony of ICAT - 2019",
        path: "/10th-convocation-ceremony-2019-chennai",
        category: "ICAT",
        cClass: "news-category2"
    },

    {
        title: "Icreas 2018 at St. Patrick's Academy",
        path: "/icreas-2018-st-patricks-academy",
        category: "Imageminds",
        cClass: "news-category3"
    },



    {
        title: "CSR Activity to Help Kerala Flood Victims",
        path: "/CSR-activity-help-kerala-flood-victims",
        category: "IMAGE",
        cClass: "news-category1"
    },
    {
        title: "8th Graduate Showcase @ ICAT, Bangalore",
        path: "/8th-graduate-showcase-2018-bangalore",
        category: "ICAT",
        cClass: "news-category2"
    },
    {
        title: "8th Student Showcase 2018 @ ICAT, Hyderabad",
        path: "/8th-student-showcase-2018-hyderabad",
        category: "ICAT",
        cClass: "news-category2"
    },
    {
        title: "Image Opens a New Centre at Channasandra, Bangalore",
        path: "/image-opens-new-centre-channasandra-2018-bangalore",
        category: "IMAGE",
        cClass: "news-category1"
    },
    {
        title: "Inter-Collegiate Cultural Fest - Miraki'18",
        path: "/inter-collegiate-cultural-fest-miraki-2018-chennai",
        category: "ICAT",
        cClass: "news-category2"
    },

    {
        title: "ICREAS 2017 @ Chellammal Vidyalaya",
        path: "/icreas-2017-chellammal-vidyalaya",
        category: "Imageminds",
        cClass: "news-category3"
    },

    {
        title: "Icreas - 2017 - The Annual Art Expo",
        path: "/icreas-2017-the-annual-art-expo",
        category: "Imageminds",
        cClass: "news-category3"
    },



    {
        title: "Image Opens a New Centre at Nagercoil, Tamilnadu",
        path: "/image-opens-new-centre-nagarcoil-2017",
        category: "IMAGE",
        cClass: "news-category1"
    },
    {
        title: "Icreas 2017 - Montfort School",
        path: "/icreas-2017-montfort-school",
        category: "Imageminds",
        cClass: "news-category3"
    },
    {
        title: "ICAT Students Won Several Awards at Delhi CG Awards 2016",
        path: "/ICAT-students-won-several-awards-delhi-CG-awards-2016",
        category: "ICAT",
        cClass: "news-category2"
    },
    {
        title: "ICAT Students Won Various Prizes at 'Kalaavaibhava' Cultural Fest",
        path: "/ICAT-students-won-various-prizes-at-kalaavaibhava-cultural-fest-2016",
        category: "ICAT",
        cClass: "news-category2"
    },
    {
        title: "Inauguration @ St. Joseph's College",
        path: "/inauguration-at-st-joseph-college-2016",
        category: "Imageminds",
        cClass: "news-category3"
    },
    {
        title: "ICREAS 16 - St. Micheal's Academy",
        path: "/icreas-2016-st-micheal-academy",
        category: "Imageminds",
        cClass: "news-category3"
    },
    {
        title: "Launch of Creative Career Progam",
        path: "/launch-creative-career-program-2015-bangalore",
        category: "IMAGE",
        cClass: "news-category1"
    },
    {
        title: "Image Advanced Learning System Launch Meet",
        path: "/image-advanced-learning-system-launch-meet",
        category: "IMS",
        cClass: "news-category1"
    },
    {
        title: "UG & PG Convocation 2012",
        path: "/ug-pg-convocation-2012",
        category: "ICAT",
        cClass: "news-category2"
    },
    {
        title: "Seminar on Visual Effects in Enthiran",
        path: "/seminar-visual-effects-enthiran",
        category: "ICAT",
        cClass: "news-category2"
    },
    {
        title: "Game Grind 2012",
        path: "/game-grind-2012-chennai",
        category: "ICAT",
        cClass: "news-category2"
    },
]

export default function NewsList() {
    const location = useLocation();
    const isLinkActive = (pathname) => location.pathname === pathname;
    return (
        <>
            {NewsLt.map((item, index) => {
                if (item.tClass) {
                    return (
                        <li key={index} className={isLinkActive(item.path) ? 'active' : ''}>
                            <Link to={item.path}>
                                <h6 className={`${item.tClass} f-16 fw-500`}>{item.title}</h6>
                                <div className={`${item.cClass} d-in-blk`}>{item.category}</div>
                                <p className="float-end d-in-blk f-12 txt-clr-1">View More</p>
                            </Link>
                        </li>
                    )
                }
                return (
                    <li key={index} className={isLinkActive(item.path) ? 'active' : ''}>
                        <Link to={item.path}>
                            <h6 className='f-16 fw-500'>{item.title}</h6>
                            <div className={`${item.cClass} d-in-blk`}>{item.category}</div>
                            <p className="float-end d-in-blk f-12 txt-clr-1">View More</p>
                        </Link>
                    </li>
                )
            })
            }
        </>
    )
}
