import Home from "./Pages/Home";

import ImageCreativeEducation from "./Pages/creative-solutions/image-creative-education";
import Icat from "./Pages/creative-solutions/icat";
import Imageminds from "./Pages/creative-solutions/imageminds";

import AboutTheFounderCEO from "./Pages/About/about-the-Founder-CEO";
import milestones from "./Pages/About/milestones";

import NewsEvents from "./Pages/NewsEvents/news-events";
import LaunchingCreativeTechnologiesWithGenAIChennai2024 from "./Pages/NewsEvents/launching-creative-technologies-with-gen-ai-chennai-2024";
import IacPrizeDistributionCeremonyChennai2024 from "./Pages/NewsEvents/iac-prize-distribution-ceremony-chennai-2024";
import PhotographyContestWinnersRunnerPrizeDistribution from "./Pages/NewsEvents/photography-contest-winners-runner-prize-distribution";
import ComfusionismMiraki2023Chennai from "./Pages/NewsEvents/comfusionism-miraki-2023-chennai";
import AVGCStakeholderConsultationWorkshop from "./Pages/NewsEvents/AVGC-stakeholder-consultation-workshop";
import RampcultureImageFashionDeptStudentsParticipatedAvantGardeFashionShow2023 from "./Pages/NewsEvents/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023";
import InterCollegiateCulturalFestMiraki2023 from "./Pages/NewsEvents/inter-collegiate-cultural-fest-miraki-2023-chennai";
import FifteenthGraduationShowcaseOfICATDesignMediaCollegeChennai from "./Pages/NewsEvents/15th-graduate-showcase-2023-chennai";
import ThirteenthGraduationShowcaseOfICATDesignMediaCollegeBangalore from "./Pages/NewsEvents/13th-graduate-showcase-2023-bangalore";
import TwelvethGraduationShowcaseOfICATDesignMediaCollegeHyderabad from "./Pages/NewsEvents/12th-graduate-showcase-2023-hyderabad";
import FourteenthGraduationShowcaseOfICATDesignMediaCollegeChennai from "./Pages/NewsEvents/14th-graduate-showcase-2022-chennai";
import TwelvethGraduationShowcaseOfICATDesignMediaCollegeBangalore from "./Pages/NewsEvents/12th-graduate-showcase-2022-bangalore";
import StudentShowcaseOfICATDesignMediaCollegeChennai from "./Pages/NewsEvents/student-showcase-2021-chennai";
import EleventhBCUConvocation2020 from "./Pages/NewsEvents/11th-bcu-convocation-ceremony-2020";
import InterCollegiateCulturalFestMiraki2020Bangalore from "./Pages/NewsEvents/inter-collegiate-cultural-fest-miraki-2020-bangalore";
import InterCollegiateCulturalFestMiraki2020Chennai from "./Pages/NewsEvents/inter-collegiate-cultural-fest-miraki-2020-chennai";
import NinethGraduationShowcaseOfICATDesignMediaCollegeBangalore from "./Pages/NewsEvents/9th-graduate-showcase-2019-bangalore";
import NinethGraduationShowcaseOfICATDesignMediaCollegeHyderabad from "./Pages/NewsEvents/9th-graduate-showcase-2019-hyderabad";
import EleventhGraduationShowcaseOfICATDesignMediaCollegeChennai from "./Pages/NewsEvents/11th-graduate-showcase-2019-chennai";
import IMAGEMINDSWonTheMostInclusiveEducationalProgrammeGoldAward from "./Pages/NewsEvents/won-most-inclusive-educational-programme-gold-award";
import RajaRajaChozhan2DAnimationShortFilmReleased from "./Pages/NewsEvents/raja-raja-chozhan-2D-animation-short-film-released-2019";
import InterCollegiateCulturalFestMiraki2K19Chennai from "./Pages/NewsEvents/inter-collegiate-cultural-fest-miraki-2019-chennai";
import InterCollegiateCulturalFestMiraki2K19Bangalore from "./Pages/NewsEvents/inter-collegiate-cultural-fest-miraki-2019-bangalore";
import TenthConvocationCeremonyOfICAT2019 from "./Pages/NewsEvents/10th-convocation-ceremony-2019-chennai";
import Icreas2018AtStPatricksAcademy from "./Pages/NewsEvents/icreas-2018-st-patricks-academy";
import CSRActivityToHelpKeralaFloodVictims from "./Pages/NewsEvents/CSR-activity-help-kerala-flood-victims";
import EighthGraduateShowcaseICATBangalore from "./Pages/NewsEvents/8th-graduate-showcase-2018-bangalore";
import EighthStudentShowcase2018ICATHyderabad from "./Pages/NewsEvents/8th-student-showcase-2018-hyderabad";
import ImageOpensNewCentreAtChannasandraBangalore from "./Pages/NewsEvents/image-opens-new-centre-channasandra-2018-bangalore";
import InterCollegiateCulturalFestMiraki18 from "./Pages/NewsEvents/inter-collegiate-cultural-fest-miraki-2018-chennai";
import ICREAS2017ChellammalVidyalaya from "./Pages/NewsEvents/icreas-2017-chellammal-vidyalaya";
import Icreas2017TheAnnualArtExpo from "./Pages/NewsEvents/icreas-2017-the-annual-art-expo";
import ImageOpensNewCentreAtNagercoilTamilnadu from "./Pages/NewsEvents/image-opens-new-centre-nagarcoil-2017";
import Icreas2017MontfortSchool from "./Pages/NewsEvents/icreas-2017-montfort-school";
import ICATStudentsWonSeveralAwardsAtDelhiCGAwards2016 from "./Pages/NewsEvents/ICAT-students-won-several-awards-delhi-CG-awards-2016";
import ICATStudentsWonVariousPrizesAtKalaavaibhavaCulturalFest from "./Pages/NewsEvents/ICAT-students-won-various-prizes-at-kalaavaibhava-cultural-fest-2016";
import InaugurationStJosephsCollege from "./Pages/NewsEvents/inauguration-at-st-joseph-college-2016";
import ICREAS16StMichealsAcademy from "./Pages/NewsEvents/icreas-2016-st-micheal-academy";
import LaunchOfCreativeCareerProgam from "./Pages/NewsEvents/launch-creative-career-program-2015-bangalore";
import ImageAdvancedLearningSystemLaunchMeet from "./Pages/NewsEvents/image-advanced-learning-system-launch-meet";
import UGPGConvocation2012 from "./Pages/NewsEvents/ug-pg-convocation-2012";
import SeminarOnVisualEffectsInEnthiran from "./Pages/NewsEvents/seminar-visual-effects-enthiran";
import GameGrind2012 from "./Pages/NewsEvents/game-grind-2012-chennai";

import Contact from "./Pages/contact";
import TermsAndConditions from "./Pages/terms-and-conditions";

var routes = [
    { path: "/", name: "Home", element: Home },
    { path: "/image-creative-education", name: "Image Creative Education", element: ImageCreativeEducation },
    { path: "/icat", name: "Icat", element: Icat },
    { path: "/imageminds", name: "Imageminds", element: Imageminds },
    { path: "/about-the-Founder-CEO", name: "About The Founder CEO", element: AboutTheFounderCEO },
    { path: "/milestones", name: "Milestones", element: milestones },
    { path: "/news-events", name: "News Events", element: NewsEvents },

    { path: "/launching-creative-technologies-with-gen-ai-chennai-2024", name: "Launching Creative Technologies with Gen AI Chennai 2024", element: LaunchingCreativeTechnologiesWithGenAIChennai2024 },
    { path: "/iac-prize-distribution-ceremony-chennai-2024", name: "Iac Prize Distribution Ceremony Chennai 2024", element: IacPrizeDistributionCeremonyChennai2024 },
    { path: "/photography-contest-winners-runner-prize-distribution", name: "Photography Contest Winners Runner Prize Distribution", element: PhotographyContestWinnersRunnerPrizeDistribution },
    { path: "/comfusionism-miraki-2023-chennai", name: "Comfusionism Miraki 2023 Chennai", element: ComfusionismMiraki2023Chennai },
    { path: "/AVGC-stakeholder-consultation-workshop", name: "AVGC Stakeholder Consultation Workshop", element: AVGCStakeholderConsultationWorkshop },
    { path: "/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023", name: "Rampculture Image Fashion Dept Students Participated Avant Garde Fashion Show 2023", element: RampcultureImageFashionDeptStudentsParticipatedAvantGardeFashionShow2023 },
    { path: "/inter-collegiate-cultural-fest-miraki-2023-chennai", name: "InterCollegiateCulturalFestMiraki2023", element: InterCollegiateCulturalFestMiraki2023 },
    { path: "/15th-graduate-showcase-2023-chennai", name: "FifteenthGraduationShowcaseOfICATDesignMediaCollegeChennai", element: FifteenthGraduationShowcaseOfICATDesignMediaCollegeChennai },
    { path: "/13th-graduate-showcase-2023-bangalore", name: "ThirteenthGraduationShowcaseOfICATDesignMediaCollegeBangalore", element: ThirteenthGraduationShowcaseOfICATDesignMediaCollegeBangalore },
    { path: "/12th-graduate-showcase-2023-hyderabad", name: "TwelvethGraduationShowcaseOfICATDesignMediaCollegeHyderabad", element: TwelvethGraduationShowcaseOfICATDesignMediaCollegeHyderabad },
    { path: "/14th-graduate-showcase-2022-chennai", name: "FourteenthGraduationShowcaseOfICATDesignMediaCollegeChennai", element: FourteenthGraduationShowcaseOfICATDesignMediaCollegeChennai },
    { path: "/12th-graduate-showcase-2022-bangalore", name: "TwelvethGraduationShowcaseOfICATDesignMediaCollegeBangalore", element: TwelvethGraduationShowcaseOfICATDesignMediaCollegeBangalore },
    { path: "/student-showcase-2021-chennai", name: "StudentShowcaseOfICATDesignMediaCollegeChennai", element: StudentShowcaseOfICATDesignMediaCollegeChennai },
    { path: "/11th-bcu-convocation-ceremony-2020", name: "EleventhBCUConvocation2020", element: EleventhBCUConvocation2020 },
    { path: "/inter-collegiate-cultural-fest-miraki-2020-bangalore", name: "InterCollegiateCulturalFestMiraki2020Bangalore", element: InterCollegiateCulturalFestMiraki2020Bangalore },
    { path: "/inter-collegiate-cultural-fest-miraki-2020-chennai", name: "InterCollegiateCulturalFestMiraki2020Chennai", element: InterCollegiateCulturalFestMiraki2020Chennai },
    { path: "/9th-graduate-showcase-2019-bangalore", name: "NinethGraduationShowcaseOfICATDesignMediaCollegeBangalore", element: NinethGraduationShowcaseOfICATDesignMediaCollegeBangalore },
    { path: "/9th-graduate-showcase-2019-hyderabad", name: "NinethGraduationShowcaseOfICATDesignMediaCollegeHyderabad", element: NinethGraduationShowcaseOfICATDesignMediaCollegeHyderabad },
    { path: "/11th-graduate-showcase-2019-chennai", name: "EleventhGraduationShowcaseOfICATDesignMediaCollegeChennai", element: EleventhGraduationShowcaseOfICATDesignMediaCollegeChennai },
    { path: "/won-most-inclusive-educational-programme-gold-award", name: "IMAGEMINDSWonTheMostInclusiveEducationalProgrammeGoldAward", element: IMAGEMINDSWonTheMostInclusiveEducationalProgrammeGoldAward },
    { path: "/raja-raja-chozhan-2D-animation-short-film-released-2019", name: "RajaRajaChozhan2DAnimationShortFilmReleased", element: RajaRajaChozhan2DAnimationShortFilmReleased },
    { path: "/inter-collegiate-cultural-fest-miraki-2019-chennai", name: "InterCollegiateCulturalFestMiraki2K19Chennai", element: InterCollegiateCulturalFestMiraki2K19Chennai },
    { path: "/inter-collegiate-cultural-fest-miraki-2019-bangalore", name: "InterCollegiateCulturalFestMiraki2K19Bangalore", element: InterCollegiateCulturalFestMiraki2K19Bangalore },
    { path: "/10th-convocation-ceremony-2019-chennai", name: "TenthConvocationCeremonyOfICAT2019", element: TenthConvocationCeremonyOfICAT2019 },
    { path: "/icreas-2018-st-patricks-academy", name: "Icreas2018AtStPatricksAcademy", element: Icreas2018AtStPatricksAcademy },
    { path: "/CSR-activity-help-kerala-flood-victims", name: "CSRActivityToHelpKeralaFloodVictims", element: CSRActivityToHelpKeralaFloodVictims },
    { path: "/8th-graduate-showcase-2018-bangalore", name: "EighthGraduateShowcaseICATBangalore", element: EighthGraduateShowcaseICATBangalore },
    { path: "/8th-student-showcase-2018-hyderabad", name: "EighthStudentShowcase2018ICATHyderabad", element: EighthStudentShowcase2018ICATHyderabad },
    { path: "/image-opens-new-centre-channasandra-2018-bangalore", name: "ImageOpensNewCentreAtChannasandraBangalore", element: ImageOpensNewCentreAtChannasandraBangalore },
    { path: "/inter-collegiate-cultural-fest-miraki-2018-chennai", name: "InterCollegiateCulturalFestMiraki18", element: InterCollegiateCulturalFestMiraki18 },
    { path: "/icreas-2017-chellammal-vidyalaya", name: "ICREAS2017ChellammalVidyalaya", element: ICREAS2017ChellammalVidyalaya },
    { path: "/icreas-2017-the-annual-art-expo", name: "Icreas2017TheAnnualArtExpo", element: Icreas2017TheAnnualArtExpo },
    { path: "/image-opens-new-centre-nagarcoil-2017", name: "ImageOpensNewCentreAtNagercoilTamilnadu", element: ImageOpensNewCentreAtNagercoilTamilnadu },
    { path: "/icreas-2017-montfort-school", name: "Icreas2017MontfortSchool", element: Icreas2017MontfortSchool },
    { path: "/ICAT-students-won-several-awards-delhi-CG-awards-2016", name: "ICATStudentsWonSeveralAwardsAtDelhiCGAwards2016", element: ICATStudentsWonSeveralAwardsAtDelhiCGAwards2016 },
    { path: "/ICAT-students-won-various-prizes-at-kalaavaibhava-cultural-fest-2016", name: "ICATStudentsWonVariousPrizesAtKalaavaibhavaCulturalFest", element: ICATStudentsWonVariousPrizesAtKalaavaibhavaCulturalFest },
    { path: "/inauguration-at-st-joseph-college-2016", name: "InaugurationStJosephsCollege", element: InaugurationStJosephsCollege },
    { path: "/icreas-2016-st-micheal-academy", name: "ICREAS16StMichealsAcademy", element: ICREAS16StMichealsAcademy },
    { path: "/launch-creative-career-program-2015-bangalore", name: "LaunchOfCreativeCareerProgam", element: LaunchOfCreativeCareerProgam },
    { path: "/image-advanced-learning-system-launch-meet", name: "ImageAdvancedLearningSystemLaunchMeet", element: ImageAdvancedLearningSystemLaunchMeet },
    { path: "/ug-pg-convocation-2012", name: "UGPGConvocation2012", element: UGPGConvocation2012 },
    { path: "/seminar-visual-effects-enthiran", name: "SeminarOnVisualEffectsInEnthiran", element: SeminarOnVisualEffectsInEnthiran },
    { path: "/game-grind-2012-chennai", name: "GameGrind2012", element: GameGrind2012 },
    { path: "/contact", name: "Contact", element: Contact },
    { path: "/terms-and-conditions", name: "Terms and Conditions", element: TermsAndConditions },
]

export default routes;